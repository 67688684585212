import { EventHandler } from '@azure/msal-browser/dist/event/EventHandler'
import logoImage from './images/logo.png'
import logoNoTextImage from './images/logoNoText.png'
import './Logo.scss'

type ComponentProps = {
  handleRefreshApplication: () => void
}

const handleClick = (props:ComponentProps) => {
  props.handleRefreshApplication()
}

const Logo = (props:ComponentProps) => {

  return (
    <div id='logo' onClick={() => handleClick(props)}>
      <img id='logo-image' title='Opdater' alt='' src={logoImage} />
      <img id='logo-no-text-image' title='Opdater' alt='' src={logoNoTextImage} />
    </div>
  )
}

export default Logo