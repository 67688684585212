import axios, { AxiosRequestConfig } from "axios"
import IEntityIncident from '../../interfaces/entityIncident/IEntityIncident'
import cryptoJs from 'crypto-js'
import { reactMainApiBaseUrl, customerKey, msalMainApiRequest } from "../../../configuration"
import { IPublicClientApplication } from '@azure/msal-browser'
import GetAccessToken from '../../functions/security/AccessTokenProvider'


export default async function CreateIncidentAction(publicClientApplication:IPublicClientApplication, customerID:string, actionName:string, entityIncidents: IEntityIncident[]) {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)

  const parameters = await createRequestParameters()

  let actions:any[] = entityIncidents.map((entityIncident:IEntityIncident, entityIncidentIndex:number) => {
    let entityIDHash:string = cryptoJs.SHA256(entityIncident.entity.entityID.toLowerCase()).toString()
    return {
        "name": actionName,
        "sensorID": entityIncident.entity.sensorID,
        "spotID": entityIncident.entity.spotID,
        "entityType": entityIncident.entity.entityType,
        "entityLocalID": (entityIncident.entity.entityLocalID === undefined ? entityIncident.entity.entityID : entityIncident.entity.entityLocalID),
        "entityIDHash": (entityIncident.entity.entityLocalID === undefined ? '' : entityIDHash)
      }  

  })

  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/actions'),
    method: 'POST',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
    data: actions
  }

  return axios(requestConfiguration)
  .then(result => result.data)
  .catch(event => Error("Could not execute the action.\n\n" + event))
}

const createRequestParameters = async () => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)

  return parameters
}