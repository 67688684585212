import './incidentListContextMenu.scss'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import contextMenuImage from './../../../../../common/images/context-menu/contextMenuLight.png'
import { useMsal } from '@azure/msal-react'
import IEntityIncident from './../../../../../common/interfaces/entityIncident/IEntityIncident'
import CreateIncidentAction from './../../../../../common/services/action/ActionServiceService'

type ComponentProps = {
  customerID:string
  incidentSelection?: IEntityIncident[]
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
}

const IncidentListContextMenu = (props:ComponentProps) => {

  const { instance } = useMsal()

  const renderContextMenu:any = (entityIncidents:IEntityIncident[]) => (
    <Popover id='incident-list-context-menu-popover'>
      <Popover.Header className='incident-list-context-menu-popover-header'>Vælg handling for valgte emner</Popover.Header>
      <Popover.Body className='incident-list-context-menu-popover-body'>
        {
          props.incidentSelection && props.incidentSelection.length > 0 && 
            <div className='incident-list-context-menu-popover-body-menu-item' onClick={handleCreateActionDelete}>Slet {props.incidentSelection.length} stk. permanent </div>
        }
      </Popover.Body>
    </Popover>
  )

  const handleCreateActionDelete = (event: React.MouseEvent<HTMLElement>) => {
    if (props.incidentSelection) {
      CreateIncidentAction(instance, props.customerID, 'Delete', props.incidentSelection)
      props.handleEntityIncidentsToRemoveChange(props.incidentSelection)
      
    }
    hidePopover('incident-list-context-menu-popover')
  }

  const hidePopover = (popoverID: string) => {
    const popover = document.getElementById(popoverID)
    popover?.style.setProperty('display', 'none')
  }


  return (
    <> 
      <OverlayTrigger trigger={['click']} rootClose={true} placement='bottom' overlay={renderContextMenu(props.incidentSelection) }>
        {
          props.incidentSelection && props.incidentSelection.length > 0 ? 
            <div className='incident-list-context-menu'>
              <img className='incident-list-context-menu-image highlight-context-menu' alt='' src={contextMenuImage}></img>
            </div>
          : 
            <div></div>
        }        
      </OverlayTrigger>
    </>
  )
}

export default IncidentListContextMenu
