import { Configuration, RedirectRequest } from "@azure/msal-browser";

// ToDo: Make this file case-correct and assemble in one config object.
export const msalConfiguration:Configuration = {
    auth: {
        clientId: "be447a98-668e-429b-b166-14545e2ab6d6"

    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
}

export const msalLoginRequest:RedirectRequest = {
    scopes: ["user.read"]
}


export const msalMainApiRequest:RedirectRequest = {
     scopes: ["api://be447a98-668e-429b-b166-14545e2ab6d6/user_impersonation"]
}

export const currentLanguageCode:string = "da"

export const customerKey:string = "sense-react"

export const reactMainApiBaseUrl = (customerID:string):string => {
   return("https://func-sensereactapi-" + customerID + "-prod.azurewebsites.net")
}

export const reactMainApiIncidentRetrievalPoolSize = 1000
