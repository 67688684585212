import './IncidentMatchResultExcerpts.scss'

import IEntityIncident from '../../../../../../common/interfaces/entityIncident/IEntityIncident'
import IIncidentMatchResult from '../../../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from '../../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'
import IIncidentMatch from '../../../../../../common/interfaces/entityIncident/IIncidentMatch'
import React from 'react'

const HighlightMatchInExcerpt = (excerpt: string, documentPosition:number, length:number) => {

  const defaultExcerptHalfLength = 50
  const positionInExcerpt = (documentPosition < defaultExcerptHalfLength) ? documentPosition : defaultExcerptHalfLength

  const textToHighlight = excerpt.substring(positionInExcerpt, positionInExcerpt + length)
  const textBefore = excerpt.substring(0, positionInExcerpt)
  const textAfter = excerpt.substring(positionInExcerpt + length)

  return (
    <>
      {textBefore}<mark>{textToHighlight}</mark>{textAfter}
    </>
  )
}


type ComponentProps = {
  entityIncident:IEntityIncident

}

const IncidentMatchResultExcerpts = (props:ComponentProps) => {

  let locationID = ''

  return (
    <>
      <div className='incident-match-result-excerpts'>
        {
          props.entityIncident.incident.matchResult &&
          props.entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult, indexMatchResult:number) => {
              
            return (
              
              matchResult.parts.map((matchResultPart:IIncidentMatchResultPart, indexMatchResultPart:number) => {

                return (
                  matchResultPart.matches.map((match:IIncidentMatch, indexMatch:number) => {

                    const entityIncidentID:string = (props.entityIncident.entity.entityType + '-' + props.entityIncident.entity.entityID + '-' + props.entityIncident.incident.name)
                    locationID = (entityIncidentID + '-' + indexMatchResult + '-' + indexMatchResultPart + '-' + indexMatch)

                    return (
                      <React.Fragment key={locationID}>
                        <div className='excerpt text-wrapped'>{HighlightMatchInExcerpt(match.excerpt, match.position, match.length)}</div>
                      </React.Fragment>

                    )

                  })
                )
              })

            )
          })
        }
      </div> 
    </>
  )
}

export default IncidentMatchResultExcerpts