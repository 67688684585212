import './StatusDetailedAnalysisComparisonBars.scss'
import IIncidentOverview from '../../../../../common/interfaces/incidentOverview/IIncidentOverview'
import StatusDetailedAnalysisCompareBar from './statusDetailedAnalysisCompareBar/StatusDetailedAnalysisCompareBar'
import OverviewSection from '../../../../../common/components/overviewSection/OverviewSection'
import { useEffect, useState } from 'react'
import IIncidentOverviewPeriod from '../../../../../common/interfaces/incidentOverview/IIncidentOverviewPeriod'


type ComponentProps = {
  incidentOverviewDataObject:IIncidentOverview
}

const StatusDetailedAnalysisComparisonBars = (props:ComponentProps) => {

  var periodLatest:IIncidentOverviewPeriod|undefined
  var primaryTitle:string|undefined
  var primaryReactionScore:number|undefined
  var primaryQuantityScore:number|undefined
  var primarySeverityScore:number|undefined
  var secondaryTitle:string|undefined
  var secondaryReactionScore:number|undefined
  var secondaryQuantityScore:number|undefined
  var secondarySeverityScore:number|undefined

  if (props.incidentOverviewDataObject?.status.detailedAnalysis.userIncidentScores) periodLatest = props.incidentOverviewDataObject?.status.detailedAnalysis.userIncidentScores.periods[0]
  if (periodLatest)
  {
    primaryTitle = periodLatest.comparedUsers[0].title
    primaryReactionScore = periodLatest.comparedUsers[0].reactionScore
    primaryQuantityScore = periodLatest.comparedUsers[0].quantityScore
    primarySeverityScore = periodLatest.comparedUsers[0].severityScore

    secondaryTitle = periodLatest.comparedUsers[1].title
    secondaryReactionScore = periodLatest.comparedUsers[1].reactionScore
    secondaryQuantityScore = periodLatest.comparedUsers[1].quantityScore
    secondarySeverityScore = periodLatest.comparedUsers[1].severityScore
  }
  return (
    
    <OverviewSection contextID='statusDetailedAnalysisComparisonBars' incidentOverviewDataObject={props.incidentOverviewDataObject} headerTitle='Din nuværende Score'>
    {
      periodLatest &&

        <div id='status-detailed-analysis-comparison-bars'>
          {primaryTitle && secondaryTitle && primaryReactionScore != undefined && primaryReactionScore > 0 && secondaryReactionScore != undefined && <StatusDetailedAnalysisCompareBar title='Reaktions- og håndteringstid' primaryObjectTitle={primaryTitle} primaryObjectScore={primaryReactionScore} secondaryObjectTitle={secondaryTitle} secondaryObjectScore={secondaryReactionScore}></StatusDetailedAnalysisCompareBar>}
          {primaryTitle && secondaryTitle && primaryQuantityScore != undefined && primaryQuantityScore > 0 && secondaryQuantityScore != undefined && <StatusDetailedAnalysisCompareBar title='Antallet af hændelser' primaryObjectTitle={primaryTitle} primaryObjectScore={primaryQuantityScore} secondaryObjectTitle={secondaryTitle} secondaryObjectScore={secondaryQuantityScore}></StatusDetailedAnalysisCompareBar>}
          {primaryTitle && secondaryTitle && primarySeverityScore != undefined && primarySeverityScore > 0 && secondarySeverityScore != undefined && <StatusDetailedAnalysisCompareBar title='Hændelsernes alvorlighedsgrad' primaryObjectTitle={primaryTitle} primaryObjectScore={primarySeverityScore} secondaryObjectTitle={secondaryTitle} secondaryObjectScore={secondarySeverityScore}></StatusDetailedAnalysisCompareBar>}
        </div>
    }
    </OverviewSection>

  )

}


export default StatusDetailedAnalysisComparisonBars