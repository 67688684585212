import { IPublicClientApplication } from "@azure/msal-browser"
import { reactMainApiBaseUrl, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'
import axios, { AxiosRequestConfig } from "axios"
import ICustomer from "../../interfaces/customer/ICustomer"

export default async function GetCustomerDataObject(publicClientApplication:IPublicClientApplication, customerID:string):Promise<ICustomer> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters()
  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/customer'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
  }

  return axios(requestConfiguration)
    .then(result => result.data)
    .catch(event => Error("Could not retrieve customer from Sense React Api.\n\n" + event))
}

const createRequestParameters = async () => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)
  
  return parameters
}