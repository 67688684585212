import './ManualHandlingIncidentType.scss'
import severityLevel1Image from './../../../../../common/images/incident-types/incidentTypeSeverityLevel1.png'
import severityLevel2Image from './../../../../../common/images/incident-types/incidentTypeSeverityLevel2.png'
import severityLevel3Image from './../../../../../common/images/incident-types/incidentTypeSeverityLevel3.png'
import { IncidentCollectionRequestType } from './../../../../../common/types/IncidentCollectionRequestType'

type ComponentProps = {
  domain: string,
  id:string, 
  severityLevel:number, 
  title:string, 
  incidentCount:number, 
  incidentSum:number,
  handleIncidentCollectionRequest: (requestedIncidentCollectionRequest:IncidentCollectionRequestType) => void
}

const ManualHandlingIncidentTypes = (props: ComponentProps) => {

  let severityLevelImage = ''

  switch(props.severityLevel) {
      case 1: {
        severityLevelImage = severityLevel1Image
        break
      }
      case 2: {
        severityLevelImage = severityLevel2Image
        break
      }
      case 3: {
        severityLevelImage = severityLevel3Image
        break      
      }
  }
  
  let elementID = ('manual-handling-incident-type-row-' + props.id.toString())
  const percentage:number = (props.incidentCount / props.incidentSum) * 100
  const percentageLabel:string = `${Math.round(percentage)}%`
  const backgroundStyle:string = `linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255) ${percentage}%, rgba(255, 255, 255, 0.2) ${percentage}%, rgba(255, 255, 255, 0.2))`

  return (
    <div id={elementID} className='manual-handling-incident-type-row'>
      <div className='manual-handling-incident-type-severity-level'>
        <img className='manual-handling-incident-type-severity-level-image' src={severityLevelImage} alt=''/>
      </div>
      <div 
        id={props.id} 
        className='manual-handling-incident-type-title' 
        onClick={() => {
          props.handleIncidentCollectionRequest({Title: props.title, Area: 'NameRequest', Value: {StringValue: props.id}, Timestamp: new Date()})
        }}>{props.title}</div>
      <div className='manual-handling-incident-type-count'>{props.incidentCount} stk. ({percentageLabel})</div>
      <div className='manual-handling-incident-type-comparison-bar' style={{background: backgroundStyle}}></div>      
    </div>
  ) 
}

export default ManualHandlingIncidentTypes