import './IncidentMatchResultEntityMatchMap.scss'

import IIncidentMatchResultPart from './../../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'
import TranslateMatchResultAssessment from './../../../../../../common/functions/translateMatchResultAssessment/TranslateMatchResultAssessment'
import IIncidentMatch from '../../../../../../common/interfaces/entityIncident/IIncidentMatch'
import React, { useEffect } from 'react'

const IncidentMatchResultEntityMatchMap = (props:any) => {

  const canvasWidth:number = 12
  const canvasHeight:number = 200

  const matchResultPart:IIncidentMatchResultPart = props.matchResultPart
  const canvasID = (props.id)

  let assessmentListString = ''
  if (matchResultPart.assessments) {
    matchResultPart.assessments.map((assessment:string) => {
      assessmentListString += (assessmentListString === '') ? '' : ' '
      assessmentListString += TranslateMatchResultAssessment(assessment) + '.'
      return false
    })
  }
  if (assessmentListString !== '') assessmentListString = (' - ' + assessmentListString)

  useEffect(() => {
 
    const canvas = document.getElementById(canvasID)
    const canvasContext = (canvas as HTMLCanvasElement).getContext('2d')

    canvas?.addEventListener('click', function(event) {
      const elementMap = event.target as HTMLElement
      const elementLocation = document.getElementById('location-' + elementMap.id + '-0')
      elementLocation?.scrollIntoView()
      elementLocation?.classList.add('blink-location')
    }, false)
  
    if (canvas && canvasContext) {

      canvasContext.strokeStyle = '#a7871d'
      canvasContext.lineWidth = 2
      
      matchResultPart.matches.map((match:IIncidentMatch) => (
        drawMatchOnMap(canvasContext, match.position, matchResultPart.fullLength, canvasWidth, canvasHeight)        
      ))
    }
  }, [canvasID, matchResultPart.fullLength, matchResultPart.matches])

  function drawMatchOnMap (canvasContext:any, position:number, fullLength:number, width:number, height:number):void {

    const xCoordinate:number = width
    const yCoordinate:number = (height / (fullLength / position))

    canvasContext.beginPath()
    canvasContext.moveTo(0, yCoordinate)
    canvasContext.lineTo(xCoordinate, yCoordinate)
    canvasContext.stroke()
  }



  return (
    <>
      <div className={
        'incident-match-result-entity-match-map' +
        (props.indexEven) ? 
          'incident-match-result-entity-match-map incident-match-result-entity-match-map-even' : 
          'incident-match-result-entity-match-map incident-match-result-entity-match-map-odd'} 
      >

        <canvas id={canvasID} className='match-map-canvas' width={canvasWidth} height={canvasHeight} title={matchResultPart.name + assessmentListString} >
          <div>
            Your browser does not support the HTML canvas tag.
          </div>
        </canvas>
      </div>
    </>
  )

}

export default IncidentMatchResultEntityMatchMap