const TranslateMatchResultAssessment = (assessmentName:string) => {

    switch (assessmentName) {
      case 'OccurrencesAreSpreadOut':
        return 'Forekomster er spredt'
      case 'OccurrencesAreAllDifferent':
        return 'Forekomster er forskellige'
      case 'OccurrencesAreAllAlike':
        return 'Forekomster er alle ens'
      case 'OccurrencesHaveRegularity':
        return 'Forekomster er ensartede og tyder på en dataliste'
      case 'OccurrencesAreMostlyAlike':
        return 'Forekomster er hovedsagelig ens'
      case 'OccurrencesAreMostlyDifferent':
        return 'Forekomster er hovedsagelig forskellige'
      case 'OccurrencesAreGroupedInTheBeginning':
        return 'Forekomster ligger i starten'
      case 'OccurrencesAreGroupedInTheMiddle':
        return 'Forekomster ligger i midten'
      case 'OccurrencesAreGroupedInTheEnd':
        return 'Forekomster ligger i slutningen'
      default:
        return ''
    }
  }

  export default TranslateMatchResultAssessment