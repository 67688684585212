import './ManualHandlingShortcuts.scss'
import OverviewSection from '../../../../common/components/overviewSection/OverviewSection'
import StandardSearchButton from './../../../../common/components/standardButton/standardSearchButton/StandardSearchButton'
import { IncidentCollectionRequestType } from './../../../../common/types/IncidentCollectionRequestType'
import { useEffect, useState } from 'react'
import IIncidentOverview from '../../../../common/interfaces/incidentOverview/IIncidentOverview'

type ComponentProps = {
  incidentOverviewDataObject:null|undefined|IIncidentOverview
  handleIncidentCollectionRequestChange: (request:IncidentCollectionRequestType) => void
}



const ManualHandlingShortcuts = (props:ComponentProps) => {

  const searchPhraseMatchResultAssessmentDataLists = 'OccurrencesHaveRegularity'

  return (
    <OverviewSection contextID='manualHandlingShortcuts' incidentOverviewDataObject={props.incidentOverviewDataObject} headerTitle='Genveje'>
      <div id='manual-handling-shortcuts'>
        <StandardSearchButton key='incident-scope-clear-command-delete-button' status='enabled' id='incident-scope-clear-command-delete-button' title='Allerede slettet' onClick={(id, title) => {props.handleIncidentCollectionRequestChange({Title: title, Area: 'ClearCommandRequest', Value: {StringValue: 'DeleteDiscarded'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-important-button' status='enabled' id='incident-scope-important-button' title='De kritiske' onClick={(title) => {props.handleIncidentCollectionRequestChange({Title: title, Area: 'SeverityLevelRequest', Value: {NumberValue: 3}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-five-minutes-button' status='enabled' id='incident-scope-five-minutes-button' title='Har fem min.' onClick={(id, title) => {props.handleIncidentCollectionRequestChange({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'DoneInFiveMinutes'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-scope-fast-button' status='enabled' id='incident-scope-fast-button' title='De hurtige' onClick={(id, title) => {props.handleIncidentCollectionRequestChange({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'Fast'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-new-button' status='enabled' id='incident-scope-new-button' title='Fundet for nylig' onClick={(id, title) => {props.handleIncidentCollectionRequestChange({Title: title, Area: 'PredefinedRequest', Value: {StringValue: 'New'}, Timestamp: new Date()})}}/>
        <StandardSearchButton key='incident-scope-data-lists-button' status='enabled' id='incident-scope-data-lists-button' title='Datalister' onClick={() => {props.handleIncidentCollectionRequestChange({Title: 'Match-vurdering', Area: 'MatchResultAssessmentRequest', Value: {StringValue: searchPhraseMatchResultAssessmentDataLists}, Timestamp: new Date()})}}/>
      </div>
    </OverviewSection>
  )
}

export default ManualHandlingShortcuts