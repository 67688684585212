import './StatusDetailedAnalysisCompareBar.scss'

type ComponentProps = {
  title:string
  primaryObjectTitle:string
  primaryObjectScore:number
  secondaryObjectTitle:string
  secondaryObjectScore:number
}

const fitInRange = (objectScore:number) => {
  return (objectScore < 1) ? 1 : ((objectScore > 9) ? 9 : objectScore)
}


const flipScore = (score:number) => {
  return 10 - score
}

const StatusDetailedAnalysisCompareBar = (props:ComponentProps) => {

  const primaryLeftStyle:string = `${10 * Math.round(fitInRange(props.primaryObjectScore ))}%`
  const secondaryLeftStyle:string = `${10 * Math.round(fitInRange(props.secondaryObjectScore))}%`

  return (
    <div className='status-detailed-analysis-comparison-bar'>
      <div className='title'>{props.title}</div>
      <div className='bar-wrapper'>
        <div className='bar'>
          <div className='compare-object primary-object' style={{left: primaryLeftStyle}}>{props.primaryObjectTitle}</div>
          <div className='compare-object secondary-object' style={{left: secondaryLeftStyle}}>{props.secondaryObjectTitle}</div>
        </div>
      </div>
    </div>
  )
}

export default StatusDetailedAnalysisCompareBar