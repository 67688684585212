import './StandardSearchButton.scss'

type ComponentProps = {
  status:string
  id:string,
  title:string,
  onClick: (id:string, title:string) => void
}

const StandardSearchButton = (props:ComponentProps) => {
  return (
    <div id = {props.id} className = {'standard-search-button ' + props.status} onClick = {() => {if (props.status==='enabled') props.onClick(props.id, props.title)}}>
      <div className='standard-search-button-title'>{props.title}</div>
    </div>
  )
}
export default StandardSearchButton