import { AccountInfo, IPublicClientApplication, RedirectRequest } from "@azure/msal-browser"

const GetAccessToken = async (publicClientApplication:IPublicClientApplication, msalRequest:RedirectRequest): Promise<string> => {

  const account:AccountInfo|null = publicClientApplication.getActiveAccount()
  if (!account) throw Error("Access token could not be retrieved.")

  const authenticationResult = await publicClientApplication.acquireTokenSilent({
    ...msalRequest, 
    account: account})
  .catch(event => {throw Error("Accesstoken could not be retrieved.")})

  return authenticationResult.accessToken
}

export default GetAccessToken