import axios, { AxiosRequestConfig } from 'axios'
import IEntityIncident from '../../interfaces/entityIncident/IEntityIncident'
import { IncidentCollectionRequestType } from '../../types/IncidentCollectionRequestType'
import { IPublicClientApplication } from '@azure/msal-browser'
import { reactMainApiBaseUrl, reactMainApiIncidentRetrievalPoolSize, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'

export default async function GetIncidents(publicClientApplication:IPublicClientApplication, customerID:string, domain:string, incidentCollectionDataObjectRequest: IncidentCollectionRequestType):Promise<IEntityIncident[]> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)

  const parameters = await createRequestParameters(customerID, domain, incidentCollectionDataObjectRequest)

  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/incidents'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
  }

  return axios(requestConfiguration)
    .then(result => result.data.incidents)
      .catch(event => Error("Could not retrieve incidents from Sense React Api.\n\n" + event))
}

const createRequestParameters = async (customerID:string, domain:string, incidentCollectionDataObjectRequest:IncidentCollectionRequestType) => {

  const parameters = new URLSearchParams()

  parameters.append('maximum-incident-count', reactMainApiIncidentRetrievalPoolSize.toString())
  parameters.append('code', customerKey)
  parameters.append('id', customerID)
  parameters.append('domain', domain)
  switch(incidentCollectionDataObjectRequest.Area) {
    case 'PredefinedRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue) 
        parameters.append('predefined-request', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'ClearCommandRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue) 
        parameters.append('clear-command-request', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'NameRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('name', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityTypeRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('entity-type', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityTitleRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('entity-title', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityIDRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('entity-id', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityFriendlyIDRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('entity-friendly-id', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityFriendlyContainerRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('entity-friendly-wrapper', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'EntityDefaultTimestampRequest': {
      if (incidentCollectionDataObjectRequest.Value?.DatetimeValue)
        parameters.append('entity-default-timestamp', incidentCollectionDataObjectRequest.Value.DatetimeValue.toString())
      break
    }
    case 'MatchResultMatchRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('match-result-match', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'MatchResultAssessmentRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
        parameters.append('match-result-assessment', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    case 'ObservationTimestampRequest': {
      if (incidentCollectionDataObjectRequest.Value?.DatetimeValue)
        parameters.append('observation-timestamp', incidentCollectionDataObjectRequest.Value.DatetimeValue.toString())
      break
    }      
    case 'SeverityLevelRequest': {
      if (incidentCollectionDataObjectRequest.Value?.NumberValue)
        parameters.append('severity-level', (incidentCollectionDataObjectRequest.Value.NumberValue.toString()))
      break
    }
    case 'WorkloadRequest': {
      if (incidentCollectionDataObjectRequest.Value?.NumberValue) 
        parameters.append('workload', (incidentCollectionDataObjectRequest.Value.NumberValue.toString()))
      break
    }
    case 'FreeTextRequest': {
      if (incidentCollectionDataObjectRequest.Value?.StringValue)
       parameters.append('free-text', incidentCollectionDataObjectRequest.Value.StringValue.toString())
      break
    }
    default: {
    }
  }

  return parameters
}