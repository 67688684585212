import { useEffect, useState } from 'react'
import closeImage from './images/close.png'
import './CurrentIncidentCollectionTab.scss'
import { IncidentCollectionRequestType } from './../../../common/types/IncidentCollectionRequestType'
import Dropdown from 'react-bootstrap/dropdown'
import IEntityIncident from '../../../common/interfaces/entityIncident/IEntityIncident'

type ComponentProps = {
  incidentCollectionDataObjectRequest?: IncidentCollectionRequestType
  incidentCollectionDataObject?: IEntityIncident[]
  handleIncidentCollectionClose: () => void
}

const CurrentIncidentCollectionTab = (props:ComponentProps) => {

  const [incidentCollectionLocal, setIncidentCollectionLocal] = useState<IEntityIncident[]>([])

  useEffect(() => {
    if (props.incidentCollectionDataObject) setIncidentCollectionLocal(props.incidentCollectionDataObject)
    }, [props.incidentCollectionDataObject]
  )

  return (
    <div id='current-incident-collection-tab' className={props.incidentCollectionDataObject ? 'current-incident-collection-tab-visible' : 'current-incident-collection-tab-hidden'}>
      {/* <Dropdown>
        <Dropdown.Toggle id="action-menu">
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#/action-2">Eksportér alle &nbsp;{incidentCollectionLocal.length}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      <div id='incident-collection-caption'>
        Hændelser for:
      </div>
      <div id='incident-collection-name'>
        {props.incidentCollectionDataObjectRequest?.Title}
      </div>
      <div id='close-incident-collection'>
        <img id='close-incident-collection-image' src={closeImage} alt='' onClick={() => props.handleIncidentCollectionClose()}/>
      </div>
    </div>
  )
}


// const copyUniqueMatchesToClipboard = (event: React.MouseEvent<HTMLElement>) => {
//   const matchList = new Array

//   if (entityIncident.incident.matchResult !== undefined) {
//     entityIncident.incident.matchResult.map(function(origin:any, indexOrigin) {
//       origin.parts.map(function(part:any, indexPart:number) {
//         part.matches.map(function(match:any, indexMatch:number) {
//            matchList.push(match.match)
//         })    
//       })
//     })
//   }

//   let matchListUnique = [...new Set(matchList)]
//   const resultStringToCopy = matchListUnique.join('\r\n')

//   copyToClipboard(resultStringToCopy)
//                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ('popover-id-' + entityIncidentID)
// }

export default CurrentIncidentCollectionTab