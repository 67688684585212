import './IncidentTypeGroup.scss'

import severityLevel1Image from './../../../../common/images/incident-types/incidentTypeSeverityLevel1.png'
import severityLevel2Image from './../../../../common/images/incident-types/incidentTypeSeverityLevel2.png'
import severityLevel3Image from './../../../../common/images/incident-types/incidentTypeSeverityLevel3.png'
import Form from 'react-bootstrap/Form'
import IEntityIncident from './../../../../common/interfaces/entityIncident/IEntityIncident'


type ComponentProps = {
  name: string
  severityLevel: number
  entityIncidents: IEntityIncident[]
  incidentSelection?: IEntityIncident[]
  handleIncidentSelectionChange: () => void
}

const getSelectionStatusForIncidentName = (selection:IEntityIncident[], incidentName:string) => {

  if (!selection || selection.length === 0) return ''
  
  const selectionLength:number = 
    selection.filter((entityIncident:IEntityIncident) => 
      entityIncident.incident.name === incidentName).length

  return (selectionLength > 0) ? 
    selectionLength + ' valgt(e)':
    ''
}

const IncidentTypeGroup = (props: ComponentProps) => {
 
  const handleSelectionChangeByUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.entityIncidents.map((entityIncident:IEntityIncident, indexIncident:number) => {
      const elementID:string = 'selected-checkbox-' + entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name
      const element:HTMLInputElement|null = (document.getElementById(elementID) as HTMLInputElement)
      if (element) element.checked = event.target.checked 
      return false
    })
    props.handleIncidentSelectionChange()
  }

  return (
    <div className='incident-type-group' >   

      <Form.Check id={'incident-type-group-selected-checkbox-' + props.name} type='checkbox' className='incident-type-group-selected-checkbox gap-to-next' onClick={(event) => event.stopPropagation()} onChange={handleSelectionChangeByUser} />
    
      <div className='severity-level gap-to-next-small'>
        {(() => {
          switch (props.severityLevel) {
            case 1: return <img className='severity-level-image' src={severityLevel1Image} alt=''/>
            case 2: return <img className='severity-level-image' src={severityLevel2Image} alt=''/>
            case 3: return <img className='severity-level-image' src={severityLevel3Image} alt=''/>
            default:
              return null
          }
        })()}
      </div>
    
      <div className='name gap-to-next'>
        {props.name}
      </div>
    
      <div className="count-label gap-to-next">
        {
          '(' + 
          props.entityIncidents.length + 
          ' ' + 
          'stk.' + 
          ')'
        }
      </div>
    
      <div className="selection-status">
        {
          (props.incidentSelection && props.incidentSelection.length > 0) ? getSelectionStatusForIncidentName(props.incidentSelection, props.name) : ''
        }
      </div>
          
    </div>
   )

}


export default IncidentTypeGroup