import './StatusCommand.scss'

import { useState } from 'react'
import IIncidentOverview from './../../../common/interfaces/incidentOverview/IIncidentOverview'
import IIncidentOverviewComparedUser from '../../../common/interfaces/incidentOverview/IIncidentOverviewComparedUser'
import IIncidentOverviewPeriod from '../../../common/interfaces/incidentOverview/IIncidentOverviewPeriod'


const statusUpperMaximumLevel:number = 10

type ComponentProps = {
  incidentOverviewDataObject?: IIncidentOverview
}

const StatusCommand = (props:ComponentProps) => {

  var result

  // Get objects.
  var period1:IIncidentOverviewPeriod|undefined = props.incidentOverviewDataObject?.status.detailedAnalysis.userIncidentScores.periods[0]
  var period2:IIncidentOverviewPeriod|undefined = props.incidentOverviewDataObject?.status.detailedAnalysis.userIncidentScores.periods[1]
  var averedgePeriod1:number|undefined
  var averedgePeriod2:number|undefined
  var averedge:number|undefined

  if (period1)
  {
    averedgePeriod1 = ((period1?.comparedUsers[0].reactionScore + period1?.comparedUsers[0].quantityScore + period1?.comparedUsers[0].severityScore) / 3)
  }

  if (period2)
  {
    averedgePeriod2 = ((period2?.comparedUsers[0].reactionScore + period2?.comparedUsers[0].quantityScore + period2?.comparedUsers[0].severityScore) / 3)      
  }

  if (averedgePeriod1 && averedgePeriod2)
    {
      averedge = (averedgePeriod1 + averedgePeriod2) / 2 
    }
  else
    if (averedgePeriod1)
      {
        averedge = averedgePeriod1 
      }

  return (
    <div id='status-command' title='Din aktuelle score'> 
      {
          averedge && 
          Math.round(averedge) + '/10' 
      } 
    </div>  
  )
}

export default StatusCommand
