import './IncidentMatchResult.scss'
import IncidentMatchResultSummary from './summary/IncidentMatchResultSummary'
import IncidentMatchResultEntityMatchMap from './entityMatchMap/IncidentMatchResultEntityMatchMap'
import IncidentMatchResultExcerpts from './details/IncidentMatchResultExcerpts'
import IEntityIncident from './../../../../../common/interfaces/entityIncident/IEntityIncident'
import IIncidentMatchResult from './../../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from './../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'


type ComponentProps = {
  entityIncidentID:string
  visible: boolean
  entityIncident:IEntityIncident
}

const IncidentMatchResult = (props:ComponentProps) => {

  const entityIncident:IEntityIncident = props.entityIncident

  const entityIncidentID:string = (entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name)

  return (
    <div className={'incident-match-result' + (props.visible ? ' match-result-visible' : ' match-result-hidden')} >
      <div className='incident-match-result-summary-wrapper'>
        <IncidentMatchResultSummary entityIncident={entityIncident}></IncidentMatchResultSummary>
      </div>
      <div className='incident-match-result-entity-match-map-wrapper'>
      {
        entityIncident.incident.matchResult && 
        entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult, indexMatchResult:number) => {
          return (
            matchResult.origin === 'BinaryContent' &&
            matchResult.parts.map((matchResultPart:IIncidentMatchResultPart, indexMatchResultPart:number) => {
              const entityIncidentID:string = (entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name  + '-' + indexMatchResult + '-' + indexMatchResultPart)
              return <IncidentMatchResultEntityMatchMap key={entityIncidentID} id={entityIncidentID} indexEven={indexMatchResultPart % 2 === 0} matchResultPart={matchResultPart} />
            })
        )
        })
      }           
      </div>
      <div className='incident-match-result-details-wrapper'>
        <IncidentMatchResultExcerpts key={entityIncidentID} entityIncident={entityIncident}></IncidentMatchResultExcerpts>
      </div>
    </div>
  )
}

export default IncidentMatchResult
