import userImage from './images/user.png'
import { UnauthenticatedTemplate, AuthenticatedTemplate, useMsal } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"
import StandardActionButton from './../../../common/components/standardButton/standardActionButton/StandardActionButton'
import { msalLoginRequest } from "../../../configuration"
import './User.scss'

const User = () => {
  
  const {instance, inProgress, accounts } = useMsal()

  if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect && accounts.length === 0)
    instance.loginRedirect(msalLoginRequest)

  return (
    <div id='user'>
      <UnauthenticatedTemplate>
        {
          !(inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) ?
            null
          :
            <div id='user-sign-in'>
              <StandardActionButton status='enabled' id='user-sign-in-button' title='Log ind' onClick={(event:any) => instance.loginRedirect(msalLoginRequest)}/>
            </div>              
        }
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div id='user-details'>
          <div id='user-name'>
            {accounts[0]?.name}
          </div>
          <div id='user-organization-name'>
            {accounts[0]?.username}
          </div>
          <div id='user-icon'>
            <img id='user-icon-image' title='Log ud' src={userImage} alt='' onClick={(event:any) => instance.logoutRedirect()}/>
          </div>
        </div>
      </AuthenticatedTemplate>
    </div>
  )
}

export default User
