import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import './index.scss'
import App from './App'
import { createRoot } from 'react-dom/client'

import { msalConfiguration, currentLanguageCode } from "./configuration"
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { StrictMode } from "react"


const publicClientApplicationInstance:PublicClientApplication = new PublicClientApplication(msalConfiguration)

publicClientApplicationInstance.initialize().then(() => {

  publicClientApplicationInstance.handleRedirectPromise().then(
    authenticationResult => 
      {
        const account = publicClientApplicationInstance.getActiveAccount()
        if(!account){
          publicClientApplicationInstance.loginRedirect()
        }
      }
    ).catch(error => 
      {
        console.log(error)
  })

  const accounts = publicClientApplicationInstance.getAllAccounts()
  if (accounts.length > 0) {

    publicClientApplicationInstance.setActiveAccount(accounts[0])
    publicClientApplicationInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult
          const account = payload.account
          publicClientApplicationInstance.setActiveAccount(account)
      }
    })
  }

  document.documentElement.lang = currentLanguageCode
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <StrictMode>
      <App publicClientApplication={publicClientApplicationInstance} />
    </StrictMode>
  )

})