import './IncidentOverview.scss'

import AutomaticHandlingRecommendations from './automaticHandling/AutomaticHandlingRecommendations'

import ManualHandlingIncidentTypes from './manualHandling/manualHandlingIncidentTypes/ManualHandlingIncidentTypes'
import ManualHandlingShortcuts from './manualHandling/manualHandlingShortcuts/ManualHandlingShortcuts'
import ManualHandlingTextSearch from './manualHandling/manualHandlingTextSearch/ManualHandlingTextSearch'
import IncidentCollectionRequestType from './../../common/types/IncidentCollectionRequestType'
import IncidentActionRequestType from './../../common/types/IncidentActionRequestType'
import IIncidentOverview from '../../common/interfaces/incidentOverview/IIncidentOverview'
import { useMsal } from '@azure/msal-react'
import { useEffect} from 'react'
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { msalMainApiRequest } from '../../configuration'
import StatusDetailedAnalysisComparisonBars from './status/statusDetailedAnalysis/statusDetailedAnalisysComparisonBars/StatusDetailedAnalysisComparisonBars'
import StatusDetailedAnalysisComparisonPeriodGraph from './status/statusDetailedAnalysis/statusDetailedAnalisysComparisonPeriodGraph/StatusDetailedAnalysisComparisonPeriodGraph'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'
import DomainVideoChannel from './domainVideoChannel/DomainVideoChannel'


type ComponentProps = {
  customerID: string
  domain: string
  incidentOverviewDataObject: null|undefined|IIncidentOverview
  settingsMinimizeOverviewWhenRequestingCollection: boolean
  handleIncidentCollectionRequestChange: (request:IncidentCollectionRequestType) => void
  handleIncidentActionRequestChange: (request:IncidentActionRequestType) => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  incidentCollectionCloseRequest: string
}

const IncidentOverview = (props:ComponentProps) => {

  return (
    <div id='overview-sections'>

      <ManualHandlingIncidentTypes 
        domain={props.domain}
        incidentOverviewDataObject={props.incidentOverviewDataObject}
        handleIncidentCollectionRequestChange={props.handleIncidentCollectionRequestChange} />

      <ManualHandlingTextSearch 
        incidentOverviewDataObject={props.incidentOverviewDataObject}
        handleIncidentCollectionRequestChange={props.handleIncidentCollectionRequestChange} 
        incidentCollectionCloseRequest={props.incidentCollectionCloseRequest} />

      <ManualHandlingShortcuts 
        incidentOverviewDataObject={props.incidentOverviewDataObject}
        handleIncidentCollectionRequestChange={props.handleIncidentCollectionRequestChange} />

      {props.incidentOverviewDataObject?.status.detailedAnalysis.userIncidentScores && 
        <StatusDetailedAnalysisComparisonBars 
        incidentOverviewDataObject={props.incidentOverviewDataObject} />
      }
      
      <AutomaticHandlingRecommendations
        customerID={props.customerID}
        domain={props.domain}
        incidentOverviewDataObject={props.incidentOverviewDataObject}
        handleIncidentCollectionRequestChange={props.handleIncidentCollectionRequestChange} 
        handleIncidentActionRequestChange={props.handleIncidentActionRequestChange} 
        handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange} />

      {props.settingsMinimizeOverviewWhenRequestingCollection && 
        <DomainVideoChannel 
        incidentOverviewDataObject={props.incidentOverviewDataObject}
          domain={props.domain} />        
      }
        
    </div>
  )
}

export default IncidentOverview