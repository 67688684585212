import ICustomer from '../../common/interfaces/customer/ICustomer'
import './CustomerPresentation.scss'
import ICustomerSupportEntity from '../../common/interfaces/customer/ICustomerSupportEntity'
import React from 'react'

type ComponentProps = {
  customer: ICustomer
}



const CustomerPresentation = (props:ComponentProps) => {

  return (
    props.customer &&
    <div id='customer-presentation'>

      <div id='welcome'>

        <img id='background-image' src={props.customer.presentation.backgroundImageUrl} alt=''/>
        
        <div id='logo'>
          <a href={props.customer.organization.websiteUrl} target='_blank' rel="noreferrer">
            <img id='logo-image' src={props.customer.presentation.logoImageUrl} alt=''/>
         </a>
        </div>

        <div id='governance-catch-phrase'>
          {props.customer.presentation.governanceCatchPhrase}
        </div>

        <div id='overall-purpose'>
          {props.customer.presentation.overallPurpose}
        </div>

      </div>

      <div id='details'>


        <div id='support-entities'>

          <div id='support-entities-headline'>
            Nedenfor kan du se mulighederne for hjælp og support
          </div>

          <div id='support-entities-list'>
            {
              props.customer.supportEntities.map((supportEntity:ICustomerSupportEntity, indexMessage:number) => (
                (
                  <React.Fragment key={indexMessage}>
                    <div className='support-entity'>

                      <div className='support-entity-field support-entity-name '>{supportEntity.name}</div>
                      <div className='support-entity-field support-entity-role-name'>{supportEntity.role.name}</div>
                      <div className='support-entity-field support-entity-contact-initiation'>
                        <a href={supportEntity.contactInitiation.link} target='_blank' rel="noreferrer">
                          {supportEntity.contactInitiation.text}
                        </a>
                      </div>            
                    </div>
                  </React.Fragment>
                )

              ))
            }
          </div>

        </div>

      </div>

    </div> 
  )

}


export default CustomerPresentation