import './IncidentMatchResultSummary.scss'
import IEntityIncident from './../../../../../../common/interfaces/entityIncident/IEntityIncident'
import { useEffect, useState } from 'react'
import IIncidentMatchResult from '../../../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from '../../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'

type ComponentProps = {
  entityIncident: IEntityIncident
}

const IncidentMatchResultSummary = (props:ComponentProps) => {

  const entityIncident:IEntityIncident = props.entityIncident
  const textNoMatchWord = 'Ingen'

  const [matchResultCount, setMatchResultCount] = useState(0)
  const [countInMetadata, setCountInMetadata] = useState(0)
  const [countInBinaryContent, setCountInBinaryContent] = useState(0)
   
  useEffect(() => {
    if (entityIncident.incident.matchResult) {

      setMatchResultCount(entityIncident.incident.matchResult.length)

      let countingInMetadata = 0
      let countingInBinaryContent = 0

      entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult, matchResultIndex:number) => (
        
        matchResult.parts.map((matchResultPart:IIncidentMatchResultPart) => {

          const partMatchCount = matchResultPart.matches.length
        
          switch (matchResult.origin)
          {
            case 'Metadata':  
              countingInMetadata =+ partMatchCount
              break
            case 'BinaryContent':
              countingInBinaryContent =+ partMatchCount
              break
            default:
              break
          }

          return false
        })
      ))

      setCountInMetadata(countingInMetadata)
      setCountInBinaryContent(countingInBinaryContent)
    }
  }, [entityIncident.incident.matchResult])

  return (
    <>
      <div className='incident-match-result-summary'>
        <div className='occurrence-section'>
          <div className='occurrence-caption'>Forekomster er fordelt i <b>{matchResultCount}</b> del(e):</div>
          <div className='occurrence-section-item'><b>{(countInMetadata === 0) ? textNoMatchWord : countInMetadata}</b> i beskrivelse</div>
          <div className='occurrence-section-item'><b>{(countInBinaryContent === 0) ? textNoMatchWord : countInBinaryContent}</b> i indhold</div>
        </div>
      </div>
    </>
  )
}

export default IncidentMatchResultSummary