import IncidentListColumnHeaders from './incidentList/incidentListColumnHeaders/IncidentListColumnHeaders'
import IncidentList from './incidentList/IncidentList'
import { IncidentCollectionRequestType } from './../../common/types/IncidentCollectionRequestType'
import './IncidentCollection.scss'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'
import IncidentCollectionSortDetailsType from '../../common/types/IncidentCollectionSortType'
import IEntityIncidentsUnderRemoval from '../../common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'

type ComponentProps = {
  customerID:string
  domain:string
  incidentCollectionDataObjectRequest: IncidentCollectionRequestType
  handleIncidentCollectionChange: (incidentCollection:IEntityIncident[]) => void
  incidentSelection?: IEntityIncident[]
  handleIncidentSelectionChange: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  entityIncidentsUnderRemoval:null|undefined|IEntityIncidentsUnderRemoval
  incidentCollectionSortDetails: IncidentCollectionSortDetailsType
  handleIncidentCollectionSortDetailsChange: (sortDetails:IncidentCollectionSortDetailsType) => void
}

const IncidentCollection = (props:ComponentProps) => {

  return (
    <div id='incident-collection'>
      <div id='incident-list-column-header-wrapper'>
        <IncidentListColumnHeaders 
          key={props.incidentCollectionDataObjectRequest.Title}
          customerID={props.customerID}
          incidentSelection={props.incidentSelection}
          handleIncidentSelectionChange={props.handleIncidentSelectionChange}
          handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
          incidentCollectionSortDetails={props.incidentCollectionSortDetails}
          handleIncidentCollectionSortDetailsChange={props.handleIncidentCollectionSortDetailsChange}
        />
      </div>
      <div id='incident-list-wrapper'>
        <IncidentList 
          key={props.incidentCollectionDataObjectRequest.Title}
          customerID={props.customerID} 
          domain={props.domain}
          incidentCollectionDataObjectRequest={props.incidentCollectionDataObjectRequest} 
          handleIncidentCollectionChange={props.handleIncidentCollectionChange}
          incidentSelection={props.incidentSelection}
          handleIncidentSelectionChange={props.handleIncidentSelectionChange}
          handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
          entityIncidentsUnderRemoval={props.entityIncidentsUnderRemoval}
          incidentCollectionSortDetails={props.incidentCollectionSortDetails}
          handleIncidentCollectionSortDetailsChange={props.handleIncidentCollectionSortDetailsChange}
        />
      </div>
    </div>
  )

}

export default IncidentCollection