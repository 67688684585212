// TODO: any is used several places. Tydes should be used (thats why its TypeScript.)

import GetIncidentOverviewDataObject from './common/services/incidentOverview/IncidentOverviewService'
import Menu from './components/menu/Menu'
import IncidentOverview from './components/incidentOverview/IncidentOverview'
import IncidentCollection from './components/incidentCollection/IncidentCollection'
import { useEffect, useState } from 'react'
import IncidentCollectionRequestType from './common/types/IncidentCollectionRequestType'
import IncidentActionRequestType from './common/types/IncidentActionRequestType'
import IIncidentOverview from './common/interfaces/incidentOverview/IIncidentOverview'
import { AuthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react'
import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import IEntityIncident from './common/interfaces/entityIncident/IEntityIncident'
import IncidentCollectionSortDetailsType from './common/types/IncidentCollectionSortType'
import ICustomer from './common/interfaces/customer/ICustomer'
import CustomerPresentation from './components/customerPresentation/CustomerPresentation'
import { msalMainApiRequest } from './configuration'
import GetCustomerDataObject from './common/services/customer/CustomerService'
import './App.scss'
import IIncidentOverviewIncidentType from './common/interfaces/incidentOverview/IIncidentOverviewIncidentType'
import IEntityIncidentsUnderRemoval from './common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'



type AppProps = {
  publicClientApplication: IPublicClientApplication
}

const App = (props:AppProps) => {

  const [customerDataObject, setCustomerDataObject] = useState<ICustomer>()
  const [domain, setDomain] = useState<string>()

  const [initialIncidentType, setInitialIncidentType] = useState<null|string>()

  const [incidentOverviewDataObject, setIncidentOverviewDataObject] = useState<null|undefined|IIncidentOverview>()
  const [incidentCollectionDataObject, setIncidentCollectionDataObject] = useState<IEntityIncident[]>()

  const [incidentCollectionDataObjectRequest, setIncidentCollectionDataObjectRequest] = useState<IncidentCollectionRequestType>()
  const [incidentSelection, setIncidentSelection] = useState<IEntityIncident[]>()
  const [entityIncidentsUnderRemoval, setEntityIncidentsUnderRemoval] = useState<null|undefined|IEntityIncidentsUnderRemoval>()

  const [refreshApplication, setRefreshApplication] = useState<string>('')

  const [incidentCollectionCloseRequest, setIncidentCollectionCloseRequest] = useState<string>('')
 
  const [settingsMinimizeOverviewWhenRequestingCollection, setSettingsMinimizeOverviewWhenOpeningCollection] = useState(false)
  const [settingsAllowDomainVideoChannel, setSettingsAllowDomainVideoChannel] = useState(false)
  const [settingsIncidentOverviewShallow, setSettingsIncidentOverviewShallow] = useState(false)

  const [incidentActionDataObjectRequest, setIncidentActionDataObjectRequest] = useState<IncidentActionRequestType>()
  const [incidentCollectionSortDetails, setIncidentCollectionSortDetails] = useState<IncidentCollectionSortDetailsType>(
    {
      sortAcsending: [true, true, false],
      activeIndex: 2
    }

  )



  useEffect(() => {   
    const parameters:URLSearchParams = new URLSearchParams(window.location.search)
    const parameterCustomerID:string|null = parameters.get('id')
    if (parameterCustomerID) {
      GetCustomerDataObject(props.publicClientApplication, parameterCustomerID)
        .then(response => setCustomerDataObject(response))
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            props.publicClientApplication.acquireTokenRedirect({
              ...msalMainApiRequest,
              account: props.publicClientApplication.getActiveAccount() as AccountInfo
            })
          }
      })
    }

    // Parameter asking for a specific incident type to be open. 
    const localIinitialIncidentType = GetParameterInitialIncidentType()
    if (localIinitialIncidentType) setInitialIncidentType(localIinitialIncidentType)

    // Retrieve items for removal in local storage if any.
    // Strip old values.
    const entityIncidentsUnderRemovalFromStorage:null|IEntityIncidentsUnderRemoval = getEntityIncidentsUnderRemovalStorage()
    if (entityIncidentsUnderRemovalFromStorage) {
      let entityIncidentsUnderRemovalFiltered:IEntityIncidentsUnderRemoval = filterEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFromStorage)
      setEntityIncidentsUnderRemovalStorage(entityIncidentsUnderRemovalFiltered)
      setEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFiltered)
    }
console.log(customerDataObject)
  }, [])

  const filterEntityIncidentsUnderRemoval = (entityIncidentsToRemove:IEntityIncidentsUnderRemoval) => {
    let minimumDate:Date = retractMinutesFromDate(new Date(), 2)
    for (let index = (entityIncidentsToRemove.items.length - 1); index >= 0; index--) {
      let itemDate:Date = entityIncidentsToRemove.items[index].timestamp
      if (new Date(itemDate) < new Date(minimumDate)) entityIncidentsToRemove.items.splice(index, 1)
    }
    return entityIncidentsToRemove;
  }


  const retractMinutesFromDate = (date:Date, minutes:number) => {
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  }

  useEffect(() => {
  }, [entityIncidentsUnderRemoval])

  useEffect(() => { 
    if (customerDataObject) {
      handleDomainChangeRequest('Sagsbehandler+') 
    }
  }, [customerDataObject])

  useEffect(() => { 
    if (domain) { 
      handleRefreshApplicationRequest() 
    }
  }, [domain])

  useEffect(() => {
    if (refreshApplication) {
      handleIncidentCollectionCloseRequest()
      handleIncidentOverviewDataObjectRequest()
      if (initialIncidentType) {
        handleIncidentCollectionRequest({Title: initialIncidentType, Area: 'NameRequest', Value: {StringValue: initialIncidentType}, Timestamp: new Date()})       
        setInitialIncidentType(null)
      }
    }
  }, [refreshApplication])

  useEffect(() => {
  }, [refreshApplication])

  useEffect(() => {
    if (incidentCollectionDataObjectRequest) {
      var viewportWidth = window.innerWidth;
      const optimizeRealEstateThreshold = 1300;  
      if (viewportWidth < optimizeRealEstateThreshold)
        setSettingsIncidentOverviewShallow(true)
      else
        setSettingsIncidentOverviewShallow(settingsMinimizeOverviewWhenRequestingCollection)
    }
  }, [incidentCollectionDataObjectRequest])

  
  const getEntityIncidentsUnderRemovalStorage = ():null|IEntityIncidentsUnderRemoval => {
    
    const entityIncidentsUnderRemovalJson:null|string = localStorage.getItem('entityIncidentsUnderRemoval')
    return entityIncidentsUnderRemovalJson ? 
        JSON.parse(entityIncidentsUnderRemovalJson) as IEntityIncidentsUnderRemoval :
        null
  }
  
  const setEntityIncidentsUnderRemovalStorage = (entityIncidentsUnderRemoval:IEntityIncidentsUnderRemoval) => {
    localStorage.setItem('entityIncidentsUnderRemoval', JSON.stringify(entityIncidentsUnderRemoval));
 }

  const GetParameterInitialIncidentType = () => {
    const parameters:URLSearchParams = new URLSearchParams(window.location.search)
    return parameters.get('incident-type')
  }

  const handleDomainChangeRequest = (domain:string) => { 
    setDomain(domain) 
  }

  const handleRefreshApplicationRequest = () => { 
    setRefreshApplication((new Date()).toString()) 
  }

  const handleIncidentCollectionCloseRequest = () => {
    setIncidentCollectionDataObjectRequest(undefined)
    setIncidentCollectionDataObject(undefined)
    setSettingsIncidentOverviewShallow(false)
    setIncidentCollectionCloseRequest((new Date()).toString())
  }

  const handleIncidentOverviewDataObjectRequest = () => {
    if (domain) {
      if (customerDataObject?.customerID) {
        GetIncidentOverviewDataObject(props.publicClientApplication, customerDataObject?.customerID, domain)
          .then(
            response => handleIncidentOverviewDataObjectRequestResult(response))
              .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                  props.publicClientApplication.acquireTokenRedirect({
                    ...msalMainApiRequest,
                    account: props.publicClientApplication.getActiveAccount() as AccountInfo
            })
          }
        })
      }
    }
  }

  const handleIncidentOverviewDataObjectRequestResult = (incidentOverviewDataObject:IIncidentOverview) => { 
    setIncidentOverviewDataObject(incidentOverviewDataObject) 
  }

  const handleSettingsMinimizeOverviewWhenRequestingCollectionChange = (value:boolean) => { 
    setSettingsMinimizeOverviewWhenOpeningCollection(value) 
  }

  const handleSettingsAllowDomainVideoChannelChange = (value:boolean) => { 
    setSettingsAllowDomainVideoChannel(value) 
  }

  const handleIncidentCollectionSortDetailsChange = (sortDetails:IncidentCollectionSortDetailsType) => {
    setIncidentCollectionSortDetails
      (
        {
          sortAcsending: 
            [
              sortDetails.sortAcsending[0],
              sortDetails.sortAcsending[1],
              sortDetails.sortAcsending[2]
            ],
          activeIndex: sortDetails.activeIndex
        }
      )
  }

  const handleIncidentCollectionRequest = (request:IncidentCollectionRequestType) => {

    if (entityIncidentsUnderRemoval && entityIncidentsUnderRemoval?.items.length > 0)
      handleIncidentOverviewDataObjectRequest()

    setIncidentCollectionDataObjectRequest({  
      Title: request.Title,
      Area: request.Area,
      Value: {
        StringValue: request.Value?.StringValue,
        DatetimeValue: request.Value?.DatetimeValue,
        NumberValue: request.Value?.NumberValue
      },
      Timestamp: request.Timestamp
    })
  }

  const handleIncidentCollectionChange = (incidentCollection:IEntityIncident[]) => { 
    setIncidentCollectionDataObject(incidentCollection) 
  }

  const handleIncidentSelectionChange = () => {
    if (!incidentCollectionDataObject) return  
    setIncidentSelection(
      incidentCollectionDataObject.filter((entityIncident:IEntityIncident) => {
        const elementID:string = 
          'selected-checkbox-' + 
          entityIncident.entity.entityType + '-' + 
          entityIncident.entity.entityID + '-' + 
          entityIncident.incident.name
        const element:HTMLInputElement|null = (document.getElementById(elementID) as HTMLInputElement)
        return (element && element.checked)
      })
    )
  }

  const handleIncidentActionRequestChange = (request:IncidentActionRequestType) => {
    setIncidentActionDataObjectRequest({
      Action: request.Action,
      Value: {
        StringValue: request.Value?.StringValue
      },
      Timestamp: request.Timestamp
    })
  }

  const handleEntityIncidentsToRemoveChange = (entityIncidents:IEntityIncident[]) => {

    let entityIncidentsUnderRemovalLocal:IEntityIncidentsUnderRemoval = {items: []}    

    entityIncidentsUnderRemoval?.items.map(item => (
      entityIncidentsUnderRemovalLocal?.items.push({
        timestamp: item.timestamp,
        entityID: item.entityID,
        incidentName: item.incidentName,
        incidentSeverityLevel: item.incidentSeverityLevel
      })
    ))

    entityIncidents.map((entityIncident:IEntityIncident) => (
      entityIncidentsUnderRemovalLocal?.items.push({
        timestamp: new Date(),
        entityID: entityIncident.entity.entityID,
        incidentName: entityIncident.incident.name,
        incidentSeverityLevel: entityIncident.incident.severityLevel
      })
    ))

    let entityIncidentsUnderRemovalFiltered:IEntityIncidentsUnderRemoval = filterEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalLocal)
    setEntityIncidentsUnderRemovalStorage(entityIncidentsUnderRemovalFiltered)
    setEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFiltered)

  }

  const handleOverviewWrapperShadowPanelClick = () => { 
    setSettingsIncidentOverviewShallow(false) 
  }

  // const manipulateLocalIncidentOverviewDataObject = (entityIncidents:IEntityIncident[], incidentOverview:IIncidentOverview) => {
  //   entityIncidents.map((entityIncident:IEntityIncident, indexEntityIncident:number) => {
  //     incidentOverview.manualHandling.incidentTypes.map((incidentType:IIncidentOverviewIncidentType, indexIncidentType:number) => {
  //       incidentType.incidentCount -= 1
  //     })
  //   })
  // }
  
  return (

      <MsalProvider instance={props.publicClientApplication}>
      {
        customerDataObject?.customerID &&
        <div id='application'>
          <div id='menu-wrapper'>
          {
            <Menu
              incidentOverviewDataObject={incidentOverviewDataObject}
              handleRefreshApplication={handleRefreshApplicationRequest}
              handleSettingsMinimizeOverviewWhenRequestingCollectionChange={handleSettingsMinimizeOverviewWhenRequestingCollectionChange}
              settingsMinimizeOverviewWhenRequestingCollection={settingsMinimizeOverviewWhenRequestingCollection}
              handleSettingsAllowDomainVideoChannelChange={handleSettingsAllowDomainVideoChannelChange}
              handleDomainChange={handleDomainChangeRequest}
              settingsAllowDomainVideoChannel={settingsAllowDomainVideoChannel}
              incidentCollectionDataObjectRequest={incidentCollectionDataObjectRequest}
              incidentCollectionDataObject={incidentCollectionDataObject} 
              handleIncidentCollectionClose={handleIncidentCollectionCloseRequest}
            />  
          }
          </div>
          <div id='content-wrapper'>
            <AuthenticatedTemplate>
              {
                <>
                  <div id='overview-wrapper' className={settingsIncidentOverviewShallow ? 'overview-wrapper-shallow' : 'overview-wrapper-normal'}>
                    <div id='overview-wrapper-shallow-panel' onClick={() => handleOverviewWrapperShadowPanelClick()}>
                      <div id='overview-wrapper-shallow-panel-symbol'></div>
                    </div>
                    <div id='overview-wrapper-content'>
                      {
                        domain &&
                        <IncidentOverview 
                            customerID={customerDataObject.customerID}
                            domain={domain} 
                            incidentOverviewDataObject={incidentOverviewDataObject}
                            settingsMinimizeOverviewWhenRequestingCollection={settingsAllowDomainVideoChannel}
                            handleIncidentCollectionRequestChange={handleIncidentCollectionRequest}  
                            handleIncidentActionRequestChange={handleIncidentActionRequestChange}
                            handleEntityIncidentsToRemoveChange={handleEntityIncidentsToRemoveChange}
                            incidentCollectionCloseRequest={incidentCollectionCloseRequest}
                          />

                      }
                    </div>
                  </div>
                  <div id='active-result-wrapper'>

                    {
                      !incidentCollectionDataObjectRequest &&
                      <div id='customer-presentation-wrapper'>
                        <CustomerPresentation customer={customerDataObject}/>
                      </div>
                    }

                    {
                      incidentCollectionDataObjectRequest && 
                      <div id='incident-collection-wrapper'>
                        {
                          domain &&
                          <IncidentCollection 
                            customerID={customerDataObject.customerID}
                            domain={domain} 
                            incidentCollectionDataObjectRequest={incidentCollectionDataObjectRequest}
                            handleIncidentCollectionChange={handleIncidentCollectionChange}
                            incidentSelection={incidentSelection}
                            handleIncidentSelectionChange={handleIncidentSelectionChange}
                            handleEntityIncidentsToRemoveChange={handleEntityIncidentsToRemoveChange}
                            entityIncidentsUnderRemoval={entityIncidentsUnderRemoval}
                            incidentCollectionSortDetails={incidentCollectionSortDetails}
                            handleIncidentCollectionSortDetailsChange={handleIncidentCollectionSortDetailsChange}
                          />
                        }
                      </div>

                    }

                  </div>

                </>
              }
            </AuthenticatedTemplate>
          </div>
        </div>
      }
    </MsalProvider>
  )
}



export default App