const ToLocaleStringFixed = (dateTime:Date, format:string, includeTime:boolean):string => {

    let resultDateTime:string = ''
   
    switch (format.toLowerCase()) {
        case 'da-dk':
            resultDateTime =
                dateTime.toLocaleDateString('en-GB').replaceAll('/', '-') + 
                ' ' +
                (includeTime ? dateTime.toLocaleTimeString('en-GB') : '')
            break;
        default:
            resultDateTime = dateTime.toLocaleString(format)
            break;
      }

      return resultDateTime
}

export default ToLocaleStringFixed