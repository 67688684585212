import './IncidentItemContextMenu.scss'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import contextMenuImage from './../../../../../common/images/context-menu/contextMenuDark.png'
import IEntityIncident from '../../../../../common/interfaces/entityIncident/IEntityIncident'
import copyToClipboard from 'copy-to-clipboard'
import CreateIncidentAction from '../../../../../common/services/action/ActionServiceService'
import { useMsal } from '@azure/msal-react'
import IIncidentMatchResult from '../../../../../common/interfaces/entityIncident/IIncidentMatchResult'
import IIncidentMatchResultPart from '../../../../../common/interfaces/entityIncident/IIncidentMatchResultPart'
import IIncidentMatch from '../../../../../common/interfaces/entityIncident/IIncidentMatch'


type ComponentProps = {
  customerID:string
  entityIncident:IEntityIncident
  handleEntityIncidentsToRemoveChange: (entityIncidents:IEntityIncident[]) => void
}

const IncidentItemContextMenu = (props:ComponentProps) => {
  
  const { instance } = useMsal()

  const entityIncident = props.entityIncident
  
  const entityIncidentID:string = (entityIncident.entity.entityType + '-' + entityIncident.entity.entityID + '-' + entityIncident.incident.name)

  const renderContextMenu:any = (entityIncident:IEntityIncident) => (
    <Popover id={'incident-item-context-menu-popover-id-' + entityIncidentID}>
      <Popover.Header className='incident-item-context-menu-popover-header'>Vælg handling for dette emne</Popover.Header>
      <Popover.Body className='incident-item-context-menu-popover-body'>
        <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyUniqueMatchesToClipboard}>Kopier match-liste renset for dobbeltgængere</div>
        <div className='incident-item-context-menu-popover-body-menu-item' onClick={createActionDelete}>Slet permanent</div>
        <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyEntityAccessLinkToClipboard}>Kopiér link</div>
        <div className='incident-item-context-menu-popover-body-menu-item' onClick={copyEntityFriendlyContainerAccessLinkToClipboard}>Kopiér link til placeringen</div>
        {/* <div className='incident-item-context-menu-popover-body-menu-item'>Sæt mærkat - Whistleblower-relateres</div> */}
      </Popover.Body>
    </Popover>
  )

  
  const copyUniqueMatchesToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    
    const matchList = [] as string[];

    if (entityIncident.incident.matchResult) {
      entityIncident.incident.matchResult.map((matchResult:IIncidentMatchResult) => (
        matchResult.parts.map((matchResultPart:IIncidentMatchResultPart) => (
          matchResultPart.matches.map((match:IIncidentMatch) => (
             matchList.push(match.match)
          ))    
        ))
      ))
    }
  
    let matchListUnique = [...new Set(matchList)]
    const resultStringToCopy = matchListUnique.join('\r\n')
  
    copyToClipboard(resultStringToCopy)
    
    hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
  }

  const createActionDelete = (event: React.MouseEvent<HTMLElement>) => {
    const entityIncidents:IEntityIncident[] = []
    entityIncidents.push(props.entityIncident)
    CreateIncidentAction(instance, props.customerID, 'Delete', entityIncidents)
    hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    props.handleEntityIncidentsToRemoveChange(entityIncidents)
  }

  const copyEntityAccessLinkToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    if (props.entityIncident.incident.entityAccessLink) {
      copyToClipboard(props.entityIncident.incident.entityAccessLink)
      hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    }
  }

  const copyEntityFriendlyContainerAccessLinkToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    if (props.entityIncident.incident.entityFriendlyContainerAccessLink) {
      copyToClipboard(props.entityIncident.incident.entityFriendlyContainerAccessLink)
      hidePopover('incident-item-context-menu-popover-id-' + entityIncidentID)
    }
  }

  const hidePopover = (popoverID: string) => {
    const popover = document.getElementById(popoverID)
    popover?.style.setProperty('display', 'none')
  }

  return (
    <> 
      <OverlayTrigger trigger={['click']} rootClose={true} placement='auto' overlay={renderContextMenu(props.entityIncident) }>
        <div className='incident-item-context-menu'>
          <img className='incident-item-context-menu-image' src={contextMenuImage} alt=''></img>
        </div>        
      </OverlayTrigger>
    </>
  )
}

export default IncidentItemContextMenu